import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionNavigations3 from '../components/navigations/IndexSectionNavigations3';
import IndexSection__structures4 from '../components/__structures/IndexSection__structures4';
import IndexSection__structures5 from '../components/__structures/IndexSection__structures5';
import IndexSection__structures6 from '../components/__structures/IndexSection__structures6';

const meta = {
  title: 'the\u0020ORGINISED\u0020ABODE\u0020co',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionNavigations3 />
      <IndexSection__structures4 />
      <IndexSection__structures5 />
      <IndexSection__structures6 />
    </React.Fragment>
  );
}

