import React from 'react';

export default function IndexSection__structures5() {
    return (
        <React.Fragment>
            <>
                <section className="pt-12 md:pt-20 bg-coolGray-50"><div className="container mx-auto px-4 pb-16">
    <div className="max-w-lg text-center mx-auto mb-20">
      <a id="Pricing">
        <h2 className="font-heading tracking-tight mb-8 text-red-500 xs:text-4xl">PRICING</h2>
      </a>
      <p className="max-w-lg text-xl mb-8" />
    </div>
    <img className="block w-full max-w-3xl mx-auto" src="https://static.shuffle.dev/uploads/files/85/858827a4e8d31b666b8b3e9c4afd86e5d5fc8502/image-123650291-3.JPG" alt="" />
  </div>
</section>


            </>
        </React.Fragment>
    );
}

