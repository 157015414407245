import React from 'react';

export default function IndexSectionNavigations3() {
    return (
        <React.Fragment>
            <>
                <section><nav><div className="container mx-auto px-4">
      <div className="flex items-center h-36">
        <a className="inline-block" href="#">
          <img className="h-16" src="https://static.shuffle.dev/uploads/files/85/858827a4e8d31b666b8b3e9c4afd86e5d5fc8502/The-organised-abode-co-Primary-Logo-Maroon-Rgb-900px-w-144ppi.png" alt="" /></a>
        <button className="lg:hidden navbar-burger py-1 ml-auto">
          <svg width={44} height={16} viewBox="0 0 44 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width={44} height={2} rx={1} fill="black" /><rect y={14} width={44} height={2} rx={1} fill="black" /></svg></button>
        <div className="hidden lg:flex ml-auto mr-12 items-center">
          <a className="inline-block hover:underline mr-10" href="#Pricing">Pricing</a><a className="inline-block hover:underline mr-10" href="#LetsTalk">Contact</a><a className="inline-block hover:underline mr-10" href="#Socials">Socials</a>
        </div>
        <a className="group hidden lg:inline-flex items-center justify-center leading-none hover:text-white transition duration-200 font-semibold px-4 py-4 text-red-400 bg-red-500" href="#LetsTalk">
          <span className="mr-2">GET IN TOUCH</span>
          <span className="group-hover:rotate-45 transform transition duration-100">
            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L1 9" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" /><path d="M9 8.33571V1H1.66429" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" /></svg></span>
        </a>
      </div>
    </div>
  </nav><div className="navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50 hidden">
    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-50" />
  </div>
</section>


            </>
        </React.Fragment>
    );
}

